<template>
    <div class="loading_image"
        :style="{ 'min-height': minHeight, 'width': width, 'height': height, 'border-radius': borderRadius }">
        <!-- 设置这些圆角是兼容Safari -->
        <div :style="{ 'border-radius': borderRadius }" class="inner"></div>
        <img :style="{ 'border-radius': borderRadius }" :src="imageUrl" @error="imageError" @load="imageLoad" alt="image">
    </div>
</template>

<script>
import { commonMixin } from '../assets/mixin/common';
export default {
    mixins: [commonMixin],
    name: 'loading_image',
    props: {
        width: {
            type: String,
            default: () => '100%',
        },
        height: {
            type: String,
            default: () => '100%',
        },
        minHeight: {
            type: String,
            default: () => '',
        },
        imageUrl: {
            type: String,
            default: () => '',
        },
        borderRadius: {
            type: String,
            default: () => '0px',
        }
    },
    methods: {
        imageLoad() {
            this.$emit('imageLoad');
        },
    }
}
</script>

<style scoped lang="less">
.loading_image {
    overflow: hidden;
    background-color: #FEEFEB;
    position: relative;

    .inner {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        background-position: 0px 0px;
        background-repeat: repeat-y;
        background-image: linear-gradient(to right, #FEEFEB 0%, #FEEFEB 50%, #FFF3ED 70%, #FFF3ED 80%, #FEEFEB 100%);
        animation: flavours 2s linear infinite;
    }

    img {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 2;
    }

}

@keyframes flavours {
    0% {
        background-position: -100vw 0;
    }

    100% {
        background-position: 100vw 0;
    }
}
</style>