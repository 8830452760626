<template>
    <div class="guide_wrap">
        <div class="guide">
            <div class="guide_title">Your subscription has started!</div>
            <div class="guide_describe">All that's left for you to do now is follow the steps below.</div>
            <div class="guide_step">
                <div class="guide_step_item guide_step_item_line">
                    <div class="guide_step_title">Download the Litely app on your device</div>
                    <div class="guide_step_content">Based on the platform you're using, search for the Litely app for
                        Android on the Google Play or for iOS on the App Store, and proceed to download the app.</div>
                    <a class="guide_step_button" href="https://app.adjust.com/wi0ukdn">
                        Download App
                    </a>
                    <div class="guide_step_item_index">1</div>
                </div>
                <div class="guide_step_item guide_step_item_line">
                    <div class="guide_step_title">Open the app and proceed to sign in</div>
                    <div class="guide_step_content">After downloading the app, you can find the 'Already have an account'
                        option at the bottom of the home page.
                        <div class="guide_step_img">
                            <loading-image :width="'275px'" :height="'212px'"
                                :imageUrl="`${$store.state.assetsUrl}/imgs/plan/already_account.webp`"
                                :borderRadius="'20px'"></loading-image>
                        </div>
                        <div class="guide_step_item_index">2</div>
                    </div>
                </div>
                <div class="guide_step_item">
                    <div class="guide_step_title">Enter your email and password</div>
                    <div class="guide_step_content">
                        <div class="email">
                            Your email: {{ email }}
                        </div>
                        Use the email and password you used to register, and once sign in, you'll have full access to the
                        Litely app. It's that easy!
                    </div>
                    <div class="guide_step_img">
                        <loading-image :width="'275px'" :height="'212px'"
                            :imageUrl="`${$store.state.assetsUrl}/imgs/plan/email.webp`"
                            :borderRadius="'20px'"></loading-image>
                    </div>
                    <div class="guide_step_item_index">3</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingImage from '@/components/loading_image.vue';
import { myMixin } from '../assets/mixin';
import { logFirebaseEvent } from '@/assets/log/log_manager.js'
export default {
    name: 'guide',
    mixins: [myMixin],
    components: { LoadingImage },
    data() {
        return {
            email: '',
        }
    },
    created() {
        this.$store.commit("setIsApp", true);
        this.getEmail();
        // 进入到下载页的埋点
        logFirebaseEvent('F_Web_Plan_Download');
    },
    methods: {
        getEmail() {
            this.email = this.$changeLocal.getItem('email') ?? '';
        }
    }
}
</script>

<style scoped lang="less">
.guide_wrap {
    background: linear-gradient(180deg, #FFEAE1 0%, #FFC2C2 100%);
    min-height: 100vh;

    .guide {
        max-width: 765px;
        padding: 88px 22px 74px;
        margin: 0 auto;

        .guide_title {
            font-size: 32px;
            font-family: 'AvenirNext';
            font-weight: 800;
            color: #444444;
            line-height: 44px;
        }

        .guide_describe {
            font-size: 16px;
            font-family: 'AvenirNext';
            font-weight: 500;
            color: rgba(0, 0, 0, 0.5);
            line-height: 22px;
        }

        .guide_step {
            margin-top: 34px;
            text-align: left;

            .guide_step_item {
                padding-left: 56px;
                position: relative;
                padding-bottom: 21px;

                .guide_step_title {
                    font-size: 22px;
                    font-family: 'AvenirNext';
                    font-weight: 600;
                    color: rgba(51, 51, 51, 0.8);
                    line-height: 30px;
                }

                .guide_step_content {
                    margin-top: 10px;
                }

                .guide_step_item_index {
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    background: linear-gradient(312deg, #FFA769 0%, #F49EFE 100%);
                    left: 0;
                    top: -7px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    font-family: Arial-Black, Arial;
                    font-weight: 900;
                    color: #FFFFFF;
                    line-height: 24px;
                    z-index: 2;
                }

                .guide_step_img {
                    margin-top: 20px;
                    width: 275px;

                    img {
                        width: 100%;
                    }
                }

                .guide_step_button {
                    max-width: 283px;
                    width: 100%;
                    height: 58px;
                    background: #FD7C61;
                    border-radius: 29px;
                    font-size: 20px;
                    font-family: 'AvenirNext';
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 27px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    cursor: pointer;
                    margin-bottom: 18px;
                    margin-right: 20px;
                }
            }

            .guide_step_item_line::after {
                content: '';
                width: 1px;
                height: calc(100% + 5px);
                position: absolute;
                background-color: rgba(51, 51, 51, 0.1);
                left: 20px;
                top: 20px;
            }

        }

    }


    img {
        pointer-events: none;
    }
}
</style>